import { HUBSPOT_MODAL_TITLES } from '@/config/constants'
import { defineStore } from 'pinia'

interface State {
  isSupportModalOpen: boolean
  supportFormId: string
  supportFormTitle: string
}

export const useSupportModalStore = defineStore('supportModal', {
  state: (): State => {
    return {
      isSupportModalOpen: false,
      supportFormId: '',
      supportFormTitle: ''
    }
  },
  actions: {
    openSupportModal(formId: string) {
      if (!this.isSupportModalOpen) {
        this.supportFormId = formId
        this.isSupportModalOpen = true
        this.supportFormTitle = HUBSPOT_MODAL_TITLES[formId] || ''
      }
    },
    closeSupportModal() {
      if (this.isSupportModalOpen) {
        this.isSupportModalOpen = false
      }
    }
  }
})
