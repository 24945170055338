<template>
  <div id="hubspot-form"></div>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, onBeforeUnmount } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import { useSupportModalStore } from '@/stores/support-modal'
import { HUBSPOT_FORM_IDS } from '@/config/constants'

const props = defineProps<{
  formId: string
}>()

const { closeSupportModal } = useSupportModalStore()
const { user, currentVenue } = storeToRefs(useUserStore())

const HiddenHubspotFields = ['firstname', 'lastname', 'email', 'venue_name'] as const
const contactFields: (typeof HiddenHubspotFields)[number][] = ['firstname', 'lastname', 'email']

type HiddenFieldsValues = Record<(typeof HiddenHubspotFields)[number], string | null>

const getHiddenFieldsValues = (): HiddenFieldsValues => {
  return {
    firstname: user.value.first_name,
    lastname: user.value.last_name,
    email: user.value.email,
    venue_name: currentVenue.value.name
  }
}

const loadHubSpotForm = () => {
  const scriptId = 'husbpot-support-form-script'
  let scriptEl: HTMLScriptElement = document.getElementById(scriptId) as HTMLScriptElement
  if (scriptEl) {
    createForm()
  } else {
    scriptEl = document.createElement('script')
    scriptEl.type = 'text/javascript'
    scriptEl.id = scriptId
    scriptEl.src = 'https://js.hsforms.net/forms/v2.js'
    scriptEl.onload = createForm
    document.body.appendChild(scriptEl)
  }
}

const createForm = () => {
  if (window.hbspt?.forms?.create) {
    window.hbspt.forms.create({
      portalId: '5163161',
      formId: props.formId ?? '',
      target: '#hubspot-form',
      onFormReady($form: HTMLFormElement) {
        const setHiddenField = (field: (typeof HiddenHubspotFields)[number], value: any) => {
          if (props.formId === HUBSPOT_FORM_IDS.SUPPORT_FORM && !contactFields.includes(field)) {
            field = 'TICKET.' + field
          }
          $form.find(`input[name="${field}"]`).val(value).trigger('change')
        }

        const hiddenValues = getHiddenFieldsValues()
        for (const field of HiddenHubspotFields) {
          setHiddenField(field, hiddenValues[field])
        }
      }
    })
  }
}

onMounted(() => {
  loadHubSpotForm()
})

onBeforeUnmount(() => {
  closeSupportModal()
})
</script>
