<template>
  <div
    id="didbuys-item-review-modal"
    aria-hidden="true"
    class="modal fade"
    role="dialog"
    tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content border-transparent-1 border-radius-6">
        <div class="modal-body p-10">
          <div class="text-center mb-3">
            <h2 id="didbuys-item-review-modal-title">Item review ({{ newDidBuyItems.length }})</h2>
            <span id="didbuys-item-review-modal-description" class="d-block mt-2">
              These items were either added to your inventory as new items or updated an existing
              one. Override any actions as necessary - click Done when everything is correct.
            </span>
          </div>
          <div id="did-buy-table-container">
            <table id="did-buy-table">
              <thead>
                <tr>
                  <th>Existing item</th>
                  <th>New item</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in newDidBuyItems" :key="index">
                  <td v-if="Object.keys(item.existingItem).length">
                    <span class="d-block didbuy-item-name">{{ item.existingItem.name }}</span>
                    <span class="d-block didbuy-sub-item-text">
                      <span
                        :class="{
                          'didbuy-item-changed-red': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.size_value,
                            item.newItem.size_value
                          )
                        }">
                        {{ item.existingItem.size_value }}
                      </span>
                      <span
                        :class="{
                          'didbuy-item-changed-red': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.size_units_name,
                            item.newItem.size_units_name
                          )
                        }">
                        {{ item.existingItem.size_units_name }}
                      </span>
                      <span
                        :class="{
                          'didbuy-item-changed-red': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.container_name,
                            item.newItem.container_name
                          )
                        }">
                        {{ item.existingItem.container_name }}
                      </span>
                      <span
                        :class="{
                          'didbuy-item-changed-red': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.units_per_case,
                            item.newItem.units_per_case
                          )
                        }">
                        &bull; Cases of {{ item.existingItem.units_per_case }}
                      </span>
                    </span>
                    <span class="d-block">
                      <span
                        :class="[
                          {
                            'didbuy-item-changed-red': isNewDidbuyValueDifferentFromOldOne(
                              item.existingItem.sku,
                              item.newItem.sku
                            )
                          },
                          'didbuy-sub-item-text'
                        ]">
                        {{ 'SKU: ' + item.existingItem.sku }}
                      </span>
                    </span>
                    <span class="d-block">
                      <span
                        :class="{
                          'didbuy-item-changed-red':
                            isNewDidbuyValueDifferentFromOldOne(
                              item.existingItem.cost,
                              item.newItem.cost
                            ) && item.existingItem.sku === item.newItem.sku
                        }">
                        {{ '$' + $utils.roundNumber(+item.existingItem.cost, 2) }}
                      </span>
                    </span>
                  </td>
                  <td v-else></td>
                  <td>
                    <span class="d-block">
                      <span class="didbuy-item-name-link" @click="goToItemPage(item)">
                        {{ item.newItem.name }}
                        <i class="fas fa-external-link-alt"></i>
                      </span>
                    </span>
                    <span class="d-block didbuy-sub-item-text">
                      <span
                        :class="{
                          'didbuy-item-changed-green': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.size_value,
                            item.newItem.size_value
                          )
                        }">
                        {{ item.newItem.size_value }}
                      </span>
                      <span
                        :class="{
                          'didbuy-item-changed-green': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.size_units_name,
                            item.newItem.size_units_name
                          )
                        }">
                        {{ item.newItem.size_units_name }}
                      </span>
                      <span
                        :class="{
                          'didbuy-item-changed-green': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.container_name,
                            item.newItem.container_name
                          )
                        }">
                        {{ item.newItem.container_name }}
                      </span>
                      <span
                        :class="{
                          'didbuy-item-changed-green': isNewDidbuyValueDifferentFromOldOne(
                            item.existingItem.units_per_case,
                            item.newItem.units_per_case
                          )
                        }">
                        &bull; Cases of {{ item.newItem.units_per_case }}
                      </span>
                    </span>
                    <span class="d-block">
                      <span
                        :class="[
                          {
                            'didbuy-item-changed-green': isNewDidbuyValueDifferentFromOldOne(
                              item.existingItem.sku,
                              item.newItem.sku
                            )
                          },
                          'didbuy-sub-item-text'
                        ]">
                        {{ 'SKU: ' + item.newItem.sku }}
                      </span>
                    </span>
                    <span class="d-block">
                      <span
                        :class="{
                          'didbuy-item-changed-green':
                            isNewDidbuyValueDifferentFromOldOne(
                              item.existingItem.cost,
                              item.newItem.cost
                            ) && item.existingItem.sku === item.newItem.sku
                        }">
                        {{ '$' + $utils.roundNumber(+item.newItem.cost, 2) }}
                      </span>
                      <span v-html="getLastOrderChangeText(item)"></span>
                    </span>
                  </td>
                  <td v-if="!Object.keys(item.existingItem).length">
                    <span class="d-block">Added as new Item</span>
                    <span style="color: #4985c6" @click="handleSearchForItemToUpdateOpen(index)">
                      <span v-if="!indexUpdatedExistingItem.includes(index)">
                        Update existing item instead
                      </span>
                      <span v-else>
                        <i class="fas fa-check-circle mr-2"></i>
                        Existing Item Updated
                      </span>
                    </span>
                    <div v-if="didBuyItemIndexNumberIsOpen === index" class="form-group">
                      <input
                        v-model="searchForItemToUpdateQuery"
                        class="form-control d-inline-block w-100 new-input"
                        placeholder="Search for item to update"
                        type="text" />
                      <div id="existing-item-search-container">
                        <div
                          v-for="(item2, key) in itemsMatchingExistingItemSearchQuery"
                          :key="key">
                          <div class="p-2" @click="updateExistingItem(index, item2)">
                            <span class="d-block font-weight-bold">{{ item2.name }}</span>
                            <span class="d-block text-muted">
                              {{ item2.size_value }}{{ item2.size_units_name }}
                              {{ item2.container_name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td v-else>
                    <span class="d-block">Existing item updated</span>
                    <a style="color: #4985c6" @click="addAsANewItem(index)">
                      <span v-if="!indexAddedAsANewItem.includes(index)">
                        Add as new item instead
                      </span>
                      <span v-else>
                        <i class="fas fa-check-circle mr-2"></i>
                        Item Added
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer text-center justify-content-center">
          <button
            id="did-buy-close-modal-button"
            class="btn btn-info"
            data-dismiss="modal"
            type="button">
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { useUserStore } from '@/stores/user.js'
import { useItemsStore } from '@/stores/items.js'
import { useDidbuyAuditLogStore } from '@/stores/didbuy-audit-log.js'
import { defineComponent } from 'vue'

export default defineComponent({
  data() {
    return {
      searchForItemToUpdateQuery: '',
      didBuyItemIndexNumberIsOpen: null,
      auditLog: [],
      indexAddedAsANewItem: [] as number[],
      indexUpdatedExistingItem: []
    }
  },
  async mounted() {
    const { first_name, last_name, job_function } = this.user || {}
    const { id } = this.currentVenue || {}
    if ([first_name, last_name, job_function, id].every((item) => !!item)) {
      const didbuyAuditLogStore = useDidbuyAuditLogStore()
      this.auditLog = [...didbuyAuditLogStore.didbuyAuditLog]
    }
  },
  computed: {
    ...mapState(useUserStore, ['user', 'currentVenue']),
    ...mapState(useItemsStore, ['items']),
    newDidBuyItems() {
      const unseenAuditLog = this.auditLog.filter((item) => item.seen === 0)
      return unseenAuditLog.map((item) => {
        const obj = {
          existingItem: {},
          newItem: {}
        }
        if (item.action === 'update') {
          obj.existingItem = JSON.parse(item.old_value)
        }
        obj.newItem = JSON.parse(item.new_value)
        return obj
      })
    },
    itemsMatchingExistingItemSearchQuery() {
      if (this.searchForItemToUpdateQuery.length < 2) {
        return []
      }
      return this.items.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.searchForItemToUpdateQuery.toLowerCase()) !== -1
      )
    }
  },
  methods: {
    ...mapActions(useItemsStore, ['getItems']),
    getLastOrderChangeText(item) {
      const isSkuDifferent = item.newItem.sku !== item.existingItem.sku
      const costDifference = item.newItem.cost - item.existingItem.cost
      if (isSkuDifferent || costDifference === 0) {
        return ''
      } else {
        const costFormatted = this.$utils.formatCurrency(Math.abs(costDifference))
        return `<span class="ml-1 didbuy-last-order-change-text"><span style="color: #000000"><i class="fas fa-arrow-${
          costDifference > 0 ? 'up' : 'down'
        }"></i>${costFormatted}</span> from last order</span>`
      }
    },
    async goToItemPage(itemToView) {
      const [databaseItem] = this.items.filter((item) => item.id === itemToView.newItem.id)
      const route = this.$router.resolve({
        path: '/inventory/edit/drink',
        query: { id: databaseItem.id }
      })
      window.open(route.href, '_blank')
    },
    isNewDidbuyValueDifferentFromOldOne(oldValue, newValue) {
      if (oldValue === undefined) {
        return false
      }
      return oldValue !== newValue
    },
    async handleOpenModal(venue_id: number) {
      try {
        $('#didbuys-item-review-modal').modal('show')
        await $.ajax({ url: '/didbuys/audit-log', data: { seen: 1, venue_id }, method: 'PATCH' })
        sessionStorage.removeItem('seenDidbuyCheckRunningModal')
      } catch (e) {}
    },
    handleSearchForItemToUpdateOpen(index) {
      this.handleSearchForItemToUpdateClose()
      this.didBuyItemIndexNumberIsOpen = index
    },
    handleSearchForItemToUpdateClose() {
      this.searchForItemToUpdateQuery = ''
      this.didBuyItemIndexNumberIsOpen = null
    },
    async addAsANewItem(index) {
      const { existingItem: oldItem, newItem } = this.newDidBuyItems[index]
      await $.post('/didbuys/rollback', { oldItem, newItem })
      this.indexAddedAsANewItem.push(index)
    },
    async updateExistingItem(index, itemToUpdate) {
      const { newItem: itemToDelete } = this.newDidBuyItems[index]
      await $.ajax({
        url: '/didbuys/rollback',
        data: { itemToDelete, itemToUpdate },
        method: 'PUT'
      })
      this.indexUpdatedExistingItem.push(index)
      this.handleSearchForItemToUpdateClose()
    }
  }
})
</script>
