// config/constants.js
export enum URLS {
  CORRECT_SPREADSHEET_SUPPORT_URL = 'https://www.getbackbar.com/support/importing-an-existing-inventory-spreadsheet?__hstc=103662495.edfab85434b9c58469e6ba17fb7449b7.1660831276069.1668454651290.1668538368520.101&__hssc=103662495.3.1668538368520&__hsfp=3228658174&_ga=2.210372311.334571252.1668441295-2080757747.1660831275',
  SPREADSHEET_FORMAT_DOWNLOAD_URL = 'https://www.getbackbar.com/hubfs/Inventory%20Import%20Spreadsheet.xlsx'
}

export enum HUBSPOT_FORM_IDS {
  PRODUCT_SUPPORT_FEEDBACK_FORM = 'd8483cac-5ea2-4078-9f5a-6e2668a79200',
  SUPPORT_FORM = 'b709b9d4-dd1c-4ee6-9fa6-d5cbcd3af773'
}

export const HUBSPOT_MODAL_TITLES: Record<HUBSPOT_FORM_IDS, string> = {
  [HUBSPOT_FORM_IDS.PRODUCT_SUPPORT_FEEDBACK_FORM]: 'Send us your feedback',
  [HUBSPOT_FORM_IDS.SUPPORT_FORM]: 'Submit a support ticket'
}
